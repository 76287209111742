@import "../../../styles/variable";

.connect-wallet-modal {
  top: 80px;
  .ant-modal-content {
    border-radius: 4px;
  }
}

.popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 25px;
    
    .wallet-icon {
        width: 24px;
        height: 24px;
    }
    .title-popup {
        font-size: 25px;
        line-height: 21px;
        font-family: $font-poppins-bold;
        padding-bottom: 24px;
        letter-spacing: -0.05em;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $color-black-2;
        -webkit-text-fill-color: $color-white;
        text-shadow: 0px 2px 0px $color-orange;
        font-weight: $fw-bolder;
    }
    .wallet-box-line {
        position: relative;
        height: 3px;
        width: 80%;
    
        margin: auto;
        background: radial-gradient(0.36% 766.67% at 50% 50%, #000000 0%, #000000 100%);
        filter: blur(9px);
        margin-bottom: 21px;
    }

    .offer-info {
        padding-top: 0 !important;
    }

    .chain-title {
        font-size: 14px;
        line-height: 100%;
        margin: 12px 46px;
        color: #e1e2e9;
        @media only screen and (max-width: 600px) {
            margin: 8px 16px;
        }
    }

    .wallet-connect-btn {
        display: flex;
        background: $color-white;
        height: 48px;
        font-size: 18px;
        box-shadow: inset 0px 3px 0.5px #F2B109;
        border-radius: 6px;
        padding: 12px;
        align-items: center;
        margin: 8px 46px;
        cursor: pointer;
        @media only screen and (max-width: 600px) {
            margin: 8px 16px;
        }
        .wallet-type {
            color: $color-gray-4;
        }
        img {
            display: block;
            margin-right: 14px;
            &.wallet-arrow {
                margin-left: auto;
                margin-right: 0px;
            }
        }
    }

    .terra-block {
      margin-top: 34px;
    }

    .dark-modal {
        @media only screen and (max-width: 769px) {
            margin: 0 rem(16px) !important;
            width: 90%;
            max-width: rem(343px);
            .wallet-box-line {
                margin-bottom: 34px;
            }
            .popup-content {
                padding-bottom: rem(4px);
            }
            .ant-modal-body {
                padding: rem(24px) rem(20px);
            }
            .wallet-connect-btn {
                margin: rem(12px) 0;
            }
        }
    }

    .btn-container{
        display: flex;
        flex-direction: row;
        
        border-top: 1px solid #2e303e;
        justify-content: center;
        align-items: center;
        margin: rem(25px) rem(46px) 0;
        padding-top: rem(15px);
        .ant-btn{
            width: fit-content;
            height: fit-content;
        }
        &__fb{
            color: white;
            font-weight: 600;
            img{
                width: rem(35px);
                margin-right: rem(10px);
            }
        }
    }
}
