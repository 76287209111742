@import "../../../../styles/variable";

.login-container{
  width: rem(520px);
  padding: rem(21px) rem(35px);
  background: linear-gradient(180deg, #31313d 0%, #181718 100%);
  &__text{
    color: white;
    margin-bottom: rem(18px);
    font:$fw-bold rem(18px) $font-poppins-regular;
    text-align: center;
  }
  .btn-status-coming{
    color: #960606;
    position: absolute;
    width: fit-content;
    right: rem(12px);
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: fit-content;
  }
  &__btn{
    width: 100%;
    padding: rem(6px)rem(14px);
    margin: rem(10px) 0;
    color: white;
    align-items: center;
    cursor: pointer;
    .ant-col{
      height: fit-content;
    }
    .btn-image{
      width: rem(35px);
      height: rem(35px);
    }
    .btn-name{
      font:$fw-bold rem(15px) $font-poppins-regular;
    }
    .btn-status{
      font-size: rem(12px);
      text-align: end;
      color: #1cc25e;
    }
    
    &.disabled{
      opacity: 0.25;
      cursor: no-drop;
    }
  }

  &__note{
    color: #f56262;
    text-align: center;
    margin: rem(14px);
    font: $fw-bold rem(14px) $font-poppins-regular;
  }

  .btn-container{
    display: flex;
    flex-direction: row;
    border-top: 1px solid #2e303e;
    justify-content: center;
    align-items: center;
    padding-top: rem(15px);
    &__text{
      position: absolute;
      right: rem(5px);
      top:0;
      bottom:0;
      margin: auto 0;
      color: red;
      height: fit-content;
    }
    .ant-btn{
        width: fit-content;
        height: fit-content;
    }
    &__fb{
      color: white;
      font-weight: 600;
      img{
          width: rem(35px);
          margin-right: rem(10px);
      }
      &.coming-soon{
        text-align: left;
        opacity: 0.25;
        width: rem(130px)
      }
    }
  }
}
