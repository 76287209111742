@import "../../../../styles/variable";


.rule-recovery{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  position: absolute;
  right: rem(370px);
  top: rem(-50px);

  .elo-text {
    width: fit-content;
    margin-bottom: rem(-20px);
    margin-left: rem(80px);
    color: white;
    font: $fw-bold rem(14px) $font-CookieRun-Bold; 
    line-height: rem(21px);
  }

  .win-streak{
    font: $fw-bold rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    color: #F0E1C3;
  }

  .progress-container{
    display: flex;
    height: fit-content;
    align-items: center;
    margin-left: rem(35px);
    .recovery-icon{
      width: rem(37px);
      height: rem(59px);
      margin-right: rem(-21px);
      z-index: 1;
    }
    
    
    .ant-progress-steps-outer {
      background: rgb(68, 36, 14) !important;
      width: rem(198px);
      height: rem(20px);
      border-radius: rem(16px);
      border: rem(2px) solid transparent;
      background:
                  linear-gradient(#FFF2F3,#FFF2F3) padding-box,
                  linear-gradient(to bottom, #BE9C80 0%, #68492A 100%) border-box;
    }
    .ant-progress-steps-item-active{
      height: 100% !important;
      width: rem(38px) !important;
      margin-right: 1px;
      background: linear-gradient(180deg, #F9C744 0%, #DF8C00 100%);
      &:first-child{
        border-top-left-radius: rem(16px);
        border-bottom-left-radius: rem(16px);
      }
      &:nth-child(5){
        border-top-right-radius: rem(16px);
        border-bottom-right-radius: rem(16px);
      }
    }
    .ant-progress-text{
      display: none;
    }
  }
  .energy-container{
    position: relative;
    font: 700 rem(16px) $font-CookieRun-Bold;
    line-height: rem(18px);
    color: #E1E2E9;
    margin-left: 20px;
    
    &__icon{
      width: rem(45px);
      height: rem(45px);
      top: rem(-5px);
      left: rem(-10px);
      position: absolute;
    }

    &__turn{
      border: 2px solid #42210B;
      background: #131417;
      border-radius: 24.5px;
      width: 131px;
      height: 34px;
      z-index: 20;
      align-items: center;
      justify-content: center;
      display: flex;    
    }
    .add-recovery{
      position: absolute;
      right: rem(5px);
      top: rem(6px);
      width: rem(22px);
      height: rem(22px);
      &:hover{
        cursor: pointer;
      }
    }
    .ant-statistic-content{
      position: absolute;
      font-size: 0;
      background: rgba(240, 230, 217, 0.2);
      border-radius: 4px;
      z-index: -1;
      width: rem(110px);
      height: rem(31px);
      left: 10px;
      top: 26px;
      text-align: center;
      padding-top: 11px;
    }
    .ant-statistic-content-value {
      font: $fw-normal rem(12px) $font-poppins-regular;
      line-height: rem(18px);
      color: #E1E2E9;
    }
  }
  .btn-block {
    cursor: pointer;
    background-image: url("../../../../assets/images/bg-btn.png");
    width: 130px;
    height: 44px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-CookieRun-Bold;
    text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410, 0 -1px #522410;
    color: white;
    margin-left: rem(16px);
    &.big-txt {
      background-image: url("../../../../assets/images/med-bg-txt-ac.png");
      width: 140px;
      height: rem(37px);
      &.btn-active {
          background-image: url("../../../../assets/images/med-bg-txt.png");
      }
      &:hover {
          background-image: url("../../../../assets/images/med-bg-txt.png");
      }
  }
    &[data-size="small"] {
      width: 108px;
      height: 36px;
    }
    &[data-size="medium"] {
      width: 215px;
      height: 66px;
    }

    &.btn-active {
      background-image: url("../../../../assets/images/bg-btn-active.png");
    }
    &:hover {
      background-image: url("../../../../assets/images/bg-btn-active.png");
    }
  }
}

.recovery-modal{
  color: white;
  .ant-modal-body{
    width: rem(564px);
    height: rem(486px);
    padding: rem(20px) rem(40px) rem(40px) ;
  }
  &-title{
    font-family: $font-poppins-bold;
    margin-bottom: rem(19px);
    color: $color-white;
    font-size: rem(20px);
    line-height: rem(21px);
    margin-top: 0;
    text-align: center;
    img{
      width: rem(44px);
      margin-right: rem(8px);
    }
    
  }

  &-note{
    font: $fw-normal rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    padding-top: rem(22px);
  }

  .price-container{
    width: 100%;
    height: rem(76px);
    background: #1C1914;
    padding: rem(18px) rem(22px);
    margin-top: rem(12px);
    border-radius: rem(6px);

    .item-price{
      display: flex;
      div{
        color: #E89F01;
        margin: 0 rem(2px);
      }
    }
  }
  .quantity-container{
    justify-content: space-between;
    padding: rem(24px) 0 rem(17px);
    border-bottom: 1px solid #534E48;
    .purchase-attempts{
      font: $fw-normal rem(14px) $font-poppins-regular;
      line-height: rem(21px);
      color: #FAF9F9;
      div {
        font: $fw-bold rem(18px) $font-poppins-regular;
        line-height: rem(18px);
        color: #9FC5E8;
        margin-top: rem(4px);
      }
    }
    .amount-row {
      margin-bottom: 0;
      color: $color-gray;
      .left-col {
        background: $color-white;
        padding-left: 16px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top: 2px solid $color-orange;
        padding-top: 5px;
        font-size: 12px;
        line-height: 12px;
        .mb-0 {
          margin-bottom: 0 !important;
          outline: none !important;
        }
      }
    }
    .max-button {
      background: $color-orange;
      height: 100%;
      width: 100%;
      border: none;
      font-weight: $fw-bold;
      border-left: 1px solid gray;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      color: $color-white;
    }
  }
  .estimate-container{
    justify-content: space-between;
    padding: rem(34px) 0 0;
    .ant-col{
      display: flex;
      font: $fw-normal rem(12px) $font-poppins-regular;
      line-height: rem(18px);
      font-style: italic;
      color: white;

      .left-number {
        font: $fw-bold rem(24px) $font-poppins-regular;
        line-height: rem(24px);
        color: #6C6D76;
        margin-left: rem(6px);
      }
      img{
        width: rem(24px);
        height: rem(12px);
        margin: auto rem(10px);
      }
      .right-number {
        font: $fw-bold rem(24px) $font-poppins-regular;
        line-height: rem(24px);
        color: #9FC5E8;
      }

    }
    &__price{
      font-style: normal !important;
      color: #FBF2F2;
      font: $fw-normal rem(14px) $font-poppins-regular !important;
      line-height: rem(21px) !important;
      div{
        margin-left: rem(6px);
        color: #E89F01;
        font: $fw-bold rem(24px) $font-poppins-regular;
        line-height: rem(24px);
      }
    }
  }
  .bottom-modal-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}

@media (max-width: 1222px) {
  .rule-recovery{
    width: 100%;
    top: (15px);
    left: 0;
    

  }


}

@media (max-width: 575px) {
  .rule-recovery{
    flex-wrap: wrap;
    justify-content: space-around;
    .btn-block {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: rem(20px);
      width: rem(117px);
      height: rem(37px);
    }
    .progress-container{
      margin-left: 0;
    }
    .energy-container{
      width: rem(230px);
      padding-left: rem(50px);
    }

    .win-streak{
      margin-bottom: rem(16px);
      width: 100%;
    }

  }
  .recovery-modal{
    .ant-modal-body{
      width: 100%;
      height: fit-content;
    }
    .price-container{
      height: fit-content;
    }
    .quantity-container .purchase-attempts{
      margin-bottom: rem(15px);
    }
    .estimate-container{
      flex-direction: column;
      &__price {
        margin-top: rem(15px);
      }
    }
  }
}
      
