@import "../../../styles/variable";

.home-content {
    min-height: 100vh;
    position: relative;
    padding-top: rem(40px);
   
    .home-footer-image{
        margin-top:-200px;
        width: 100%;
    }

    .home-banner{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        min-height: 850px;
        height: fit-content;
        overflow: hidden;
        &-image{
            position: absolute;
            top: -70px;
            width: 100%;
        }
        &-container{
            position: absolute;
            top:-80px;
            &__season{
                position: absolute;
                bottom: 110px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: fit-content;
                letter-spacing: -0.03em;
                color: #F0E1C3;
                font: $fw-bold rem(16px) $font-poppins-regular;
                text-align: center;
                .season-time{
                    font-size: rem(14px);
                    font-weight: $fw-normal;
                    display: flex;
                    .ant-statistic-content-value{
                        margin-left: 4px;
                        font-size: rem(14px);
                        font-weight: 400;
                        display: block;
                        color: #F0E1C3;
                        margin-top: -1px;
                    }
                }
            }
        }
    }

    .home-table{
        position: absolute;
        top: 300px;
        &-container{
            width: rem(542px);
            margin: 0 rem(13px);
            background: #212025;
            border-radius: 9px;

            .title-container{
                padding: rem(12px) rem(24px);
                align-items: center;
                &-text{
                    color: #F0E1C3;
                    font:  700 rem(24px) $font-CookieRun-Regular;
                    text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410,
                        0 -1px #522410;
                    div{
                       text-shadow: none;
                       font: $fw-normal rem(14px) $font-poppins-regular;
                       line-height: rem(21px);
                    }
                }
            }
            
            .list-reward{
                background: #19191D;
                border-radius: 20px 20px 9px 9px;
                padding: rem(21px) rem(24px);
                height: rem(460px);
                position: relative;
                img{
                    height: fit-content;
                    padding: 0;
                    margin-top: -4px;
                }
                .ant-col{
                    text-align: center;
                    color: #AEADB6;
                    font:  $fw-normal rem(14px) $font-CookieRun-Regular;
                    padding: 0 0 rem(10px) ;
                }
                .list-leaderboard-item{
                    margin-bottom: rem(5px);
                    .ranking-number{
                        font-family: $font-CookieRun-Bold;
                        color: #F0E1C3;
                    }
                    .elo-icon{
                        margin-left: 4px;
                    }
                }
                .your-rank{
                    position: absolute;
                    width: 100%;
                    bottom:0;
                    left: 0;
                    padding-left: 24px;
                    padding-right: 24px;
                    padding-top: rem(17px);
                    padding-bottom: rem(14px);
                    background: linear-gradient(90deg, rgba(114, 92, 51, 0) 0%, rgba(84, 74, 50, 0.6) 50.23%, rgba(114, 92, 51, 0) 100%) ;
                    &-tag{
                        background: #44240E;
                        border-radius: 4px;
                        padding: rem(4px) rem(9px);
                        font: 700 rem(12px) $font-CookieRun-Bold; 
                        line-height: rem(18px);
                        color: #EBC276;
                        position: absolute;
                        top: rem(-13px)
                    }
                }
                &-prize{
                    text-align: end !important;
                }
                &-name{
                    text-align: start !important;
                    margin-left: 7px;
                }
                &-item{
                    &:not(:last-child){
                        border-bottom: 1px solid #212025;
                        margin-bottom: rem(21px);
                    }
                }
                .leader-board{
                    height: rem(325px);
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 3px;
                        background-color: #F5F5F5;
                        border-radius: 1px;
                        &-thumb {
                            background-color: #EBC276;
                            border-radius: 1px;

                        }
                    } 
                    
                }
            }
            
        }

    }

    .btn-block {
        cursor: pointer;
        margin: rem(31px) auto rem(12px);
        background-image: url("../../../assets/images/bg-btn-icon.png");
        width: 259px;
        height: 82px;
        padding-left: 60px;
        padding-top: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-CookieRun-Bold;
        font:  900 rem(24px) $font-CookieRun-Bold;
        line-height: rem(24px);
        color: white;
        text-align: center;
        text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410, 0 -1px #522410;
        .small-text{
            font-size: rem(14px);
        }
    }
    .register-note{
        color: #AEADB6;
        font:  400 rem(14px) $font-CookieRun-Regular;
        line-height: rem(21px);
        margin: 0 auto;
        width: fit-content;
        text-align: center;
        span{
            color: #F1B530;
            font:  700 rem(18px) $font-CookieRun-Regular;
            line-height: rem(27px);
        }
    }
    .running-title{
        max-width: rem(1110px);
        background: linear-gradient(90deg, rgba(84, 74, 50, 0.6) 0%, rgba(114, 92, 51, 0) 99.99%, rgba(114, 92, 51, 0) 100%);
        border-left: 2px solid #F2B109;
        padding: rem(9px) rem(16px);
        color: #F0E1C3;
        font:  700 rem(24px) $font-CookieRun-Regular;
        text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410,
            0 -1px #522410;
        margin: 50px auto 30px;
        text-align: center;
        align-items: end;
        div{
            color: #E89F01;
            text-shadow: none; 
            font: $fw-normal rem(14px) $font-poppins-regular;
            line-height: rem(21px);
            height: fit-content;
            margin-left: rem(8px);
        }
    }
    .game-item {
        margin: 0 auto rem(60px);
        width: fit-content;
        position: relative;
        cursor: pointer;
        &.disabled-game{
            opacity: 0.5;
            cursor: not-allowed;
        }

        &-info{
            position: absolute;
            right: 30px;
            top: 80px;
            .ant-row{
                min-width: 130px;
                justify-content: space-between;
                color: white;
                font:  700 rem(16px) $font-CookieRun-Regular;
                height: rem(21px);
                margin-bottom: rem(14px);
            }
            &.game-out{
                top: 130px
            }
        }

        &-note{
            background: linear-gradient(89.98deg, rgba(0, 0, 0, 0) 0.54%, rgba(0, 0, 0, 0.4) 50.22%, rgba(0, 0, 0, 0) 99.89%), linear-gradient(90deg, rgba(114, 92, 51, 0) 0%, rgba(84, 74, 50, 0.6) 50.23%, rgba(114, 92, 51, 0) 100%);
            backdrop-filter: blur(4px);            
            max-width: rem(525px);
            padding: rem(7px);
            text-align: center;
            margin: 0 auto;
            position: absolute;
            top: 260px;
            left: 0;
            right: 0;
            color: white

        }
    }
}

