@import "../../../styles/variable";

.register-game-page {
    background: url("../../../assets/images/bg-game-0.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: calc(100vh - 72px);
    width: 100%;
    background-position: center;
    color: white;
    overflow-x: hidden;
    margin: 0px;
    max-width: 100%;
    &.game-1 {
        background: url("../../../assets/images/bg-game-1.png") no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    &.game-3 {
        background: url("../../../assets/images/bg-game-3.png") no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    
    .logo-block {
        position: relative;
        height: 100%;
    }
    .no-seas-txt {
        font:  $fw-bold rem(28px) $font-CookieRun-Bold;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 57%;
        left: 50%;
        color: #f8d897;
        text-shadow: -1px 0 #B67D3F, 0 1px #B67D3F, 1px 0 #B67D3F,
                0 -1px #B67D3F;
    }
    .logo-nft {
        @media (max-width: 1536px) {
        }
    }
    .title-game {
        margin: 81px auto 0;
        display: block;
    }
    .time-txt {
        font-family: $font-poppins-regular;
        font-size: rem(18px);
        text-align: center;
        color: #5F3824;
        line-height: rem(27px);
        padding: rem(73px) 0 rem(10px);
    }
    .reward-box{
        background: url("../../../assets/images/reward-bg.png");
        border-radius: rem(6px);
        width: rem(638px);
        height: rem(379px);
        margin: rem(-65px) auto rem(40px);
        &__title {
            color: #F0E1C3;
            font-family: $font-CookieRun-Bold;
            text-shadow: -2px 0 #5F3824, 0 2px #5F3824, 2px 0 #5F3824,
                0 -2px #5F3824;
            font-size: 20px;
            font-weight: 900;
            text-align: center;
        }
        &__numb-txt {
            color: #44240E;
            line-height: 21px;
            font: $fw-normal rem(14px) $font-poppins-regular;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        &__detail {
            font-family: $font-CookieRun-Regular;
            font-size: rem(16px);
            line-height: rem(21px);
            font-weight: 400;
            margin-top: rem(40px);
            color: #5F3824;
            cursor: pointer;
            text-align: end;
            padding-right: rem(25px);
        }
        &__item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: rem(376px);
            margin: 0 auto;
            padding: rem(14px) 0;
            border-bottom: 1px solid #6c63632e;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;


            &:last-child {
                border: none;
            }
        }

        &__text {
            font:  $fw-normal rem(18px) $font-CookieRun-Regular;
            line-height: rem(24px);
            color: #5F3824;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: rem(19px);
                height: rem(24px);
                margin-right: rem(8px);
            }
            &.name-prize{
                justify-content: flex-start;
            }
            .ranking-number{
                width: rem(36px);
                height: rem(36px);
            }
        }
        &__lucky {
            color: #F0E1C3;
            font:  $fw-normal rem(14px) $font-CookieRun-Regular;
            text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410,
                0 -1px #522410;
        }

        &__note {
            text-align: center;
            color:#FD1212;
            line-height: 16px;
            font: $fw-normal rem(11px) $font-poppins-regular;
        }
    }
    .layout-content {
        margin: 0px auto;
        overflow-x: hidden;
        // position: absolute;
        height: 100%;
        // transform: translate(-50%, -50%);
        @media (max-width: 1536px) {
            top: 350px;
        }

        .footer-btns {
            display: flex;
            position: absolute;
            bottom: 130px;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            .btn-block {
                margin-right: 12px;
            }
        }
        .select-game {
            position: absolute;
            bottom: 0px;
            height: 130px;
            width: 100%;
            background: #111111;
            .game-container {
                display: flex;
                width: 100%;
                justify-content: center;
            
            }
            .game-sw-block {
                width: 118px;
                height: 130px;
            }
            .game-block {
                width: 118px;
                height: 130px;
                text-align: center;
                padding: 16px 0px;
                filter: brightness(40%);
                &.active {
                    color: #FFDC97;
                    filter: brightness(100%);
                }
                .game-name {
                    font-size: 14px;
                    font-family: $font-CookieRun-Bold;
                }
            }
            .logo-img {
                border-radius: 12px;
            }
        }
        .left-btns {
            position: absolute;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            display: flex;
            bottom: 0px;
            .btn-block {
                margin-right: 16px;
            }
        }
        .right-btns {
            .btn-block {
                            margin: 0 auto;

            }
            .txt-block {
                text-align: center;
            }
            .big-text {
                font-size: 24px;
                line-height: 100%;
                padding-left: 28px;
            }
            .small-text{
                padding-left: 25px;
            }
        }
        .btn-block {
            &.big-txt {
                background-image: url("../../../assets/images/med-bg-txt-ac.png");
                width: 140px;
                &.btn-active {
                    background-image: url("../../../assets/images/med-bg-txt.png");
                }
                &:hover {
                    background-image: url("../../../assets/images/med-bg-txt.png");
                }
            }

            cursor: pointer;
            background-image: url("../../../assets/images/bg-btn.png");
            width: 108px;
            height: 36px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-CookieRun-Bold;
            text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410,
                0 -1px #522410;
            &[data-size="small"] {
                width: 100px;
                height: 36px;
            }
            &[data-size="medium"] {
                width: 259px;
                height: 82px;
            }

            &.btn-active {
                background-image: url("../../../assets/images/bg-btn-active.png");
                &[data-size="small"] {
                    background-image: url("../../../assets/images/bg-btn-active-sm.png");
                }
            }
            &:hover {
                background-image: url("../../../assets/images/bg-btn-active.png");
                &[data-size="small"] {
                    background-image: url("../../../assets/images/bg-btn-active-sm.png");
                }
            }
            &.btn-play {
                background-image: url("../../../assets/images/bg-btn-icon.png");
                padding-left: rem(30px);
            }
        }
        .btn-block-2 {
            cursor: pointer;
            background-image: url("../../../assets/images/bg-btn-2.png");
            width: 82.64px;
            height: 30.09px;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-CookieRun-Bold;
            color: #44240E;
        }
    }
}
@media (max-width: 1024px) {
    .register-game-page {
        &.game-1{
            .title-game{
                width: 90%;
                margin-top: rem(27px);
            }
             .reward-box {
                top: rem(500px);
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;

            }
        }
    }

}
@media (max-width: 768px) {
    .register-game-page {
        &.game-1{
             .reward-box {
                top: rem(350px);

            }
            .right-btns {
                top: rem(780px);
            }
            .footer-btns{
                top: rem(850px);
            }
        }
    }

}

@media (max-width: 575px) {
    .register-game-page {
        background-image: url("../../../assets/images/bg-nft-game-1-mobile.png");
        &.game-1{
            height: 100vh;
            background: url("../../../assets/images/bg-game-1-mobile.png") no-repeat center center fixed; 
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            .title-game{
                width: 100%;
                margin-top: rem(27px);
            }
            .time-txt{
                top: rem(180px) !important
            }
            .reward-box {
                top: rem(199px);
            }
            .left-btns {
                transform: none;
                bottom: 20px;
            }
            .right-btns {
                transform: none;
                top: rem(620px) !important;
            }
            .footer-btns{
                top: rem(620px);
            }

        }
        .layout-content {
            min-height: 100vh;
            height: fit-content;
            top: rem(400px);
            .time-txt {
                width: 97%;
                top: rem(135px);
                border-radius: 0;
                font-size: rem(10px);
            }

            .reward-box {
                bottom: rem(90px);
            }


            .reward-box, .left-btns, .right-btns{
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                
            }
            .left-btns, .right-btns {
                justify-content: center;
                .btn-block {
                    margin-left: rem(8px);
                    margin-right: rem(8px);
                }

            }

            .right-btns{
                display: flex;
                width: 100%;
                bottom: rem(-85px);

            }

            .footer-btns{
                margin-top: rem(90px);
                flex-wrap: wrap;
                .btn-block{
                    margin: rem(6px);

                }
            }
        }

    }
}
