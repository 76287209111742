@import "../../../styles/variable";

.my-transaction {
  &__title{
    font: $fw-bold rem(32px) $font-poppins-regular;
    line-height: rem(48px);
    margin-bottom: rem(24px);
    color: white
  }
  .ant-pagination {
    text-align: end;
    margin: rem(26px) 0;
  }
  .ant-pagination-item a, .ant-pagination-prev, .ant-pagination-next{
    color: white;
    font: $fw-bold rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    .anticon{
      color: #C4C4C4;
    }
  }
  .ant-table-empty .ant-table-placeholder:hover >td{
    background-color: #1C2023;
  }
  .ant-pagination-item-active  {
    background-color: #E89F01;
    border-radius: 4px;
    border: none;
    text-align: center;
  }

  .transaction-type{
    font: $fw-bold rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    &.red{
      color: #EC1212;
    }
    &.green{
      color: #4DDA1B;
    }
  }
  .amount{
    display: flex;
    flex-direction: row;
    img{
      width: rem(20px);
      margin-right: rem(4px);
    }
  }
  .status-btn{
    font: $fw-bold rem(12px) $font-poppins-regular;
    line-height: rem(18px);
    padding: rem(7px) 0;
    width: rem(87px);
    border-radius: 4px;
    text-align: center;
    &.green{
      background: rgba(0, 177, 64, 0.4);
      border: 1px solid #00B140;
    }
    &.action{
      background: linear-gradient(180deg, #F9C744 0%, #DF8C00 100%);
      box-shadow: 0px 4px 0px #AE4B12;
      cursor: pointer;
    }
    &.red{
      background: rgba(244, 67, 54, 0.4);
      border: 1px solid #F44336;
    }
    &.yellow{
      background: rgba(255, 174, 25, 0.4);
      border: 1px solid #FFAE19;
    }

  }
  .transaction-table{
    .ant-table{
      border-radius: 9px;
      background: transparent;
    }
    .ant-table-thead{
      background: linear-gradient(180deg, #24242D 0%, #0F0F13 100%);
      border: 1px solid #2E303E;
      border-radius: 8px 8px 0px 0px;
      .ant-table-cell{
        background: transparent;
        color:#8089A9;
      }
    }
    .ant-table-tbody >tr >td, .ant-table-thead > tr > th {
      border-color:#2E303E;
    }
    .ant-table-tbody{
      background: #1C2023;
      color: #E1E2E9;
      
      .ant-table-row:hover >td{
        background: #1C2023;

      }
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 4px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 4px;
    }
    .ant-table-container {
       border: 1px solid #2E303E;
    }
  }
}


