@import "../../../styles/variable";

.star-container {
  width: 100%;
  text-align: center;
  img{
    width: rem(25px);
    height: rem(25px);
    margin: rem(4px)
  }
}
