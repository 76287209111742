@import "../../../styles/variable";

.history-game-page {
  color: #e1e2e9;
  flex:1;
  padding-top: rem(20px);
  .elo-txt {
    line-height: 100%;
    font: 600 rem(18px) $font-poppins-regular;
    color: #f0e1c3;
    margin-top: 40px;
    margin-bottom: 12px;
  }
  .row-result {
    margin-bottom: 4px;
    padding: 16px 24px;
  }
  .list-match {
    margin-right: 25px;
  }
  .row-his {
    background: rgba(0, 0, 0, 0.2);
    margin-bottom: 4px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 90px rgba(255, 184, 119, 0.3), 0px 0px 30px rgba(253, 204, 132, 0.2);
    }
  }
  .col-his {
    display: flex;
  }
  .result-txt {
    
    font: $fw-bold rem(24px) $font-CookieRun-Bold;
    text-transform: uppercase;
    margin-top: 20px;
    .vic {
      color: #f0e1c3;
      text-shadow: -2px 0 #5f3824, 0 2px #5f3824, 2px 0 #5f3824, 0 -2px #5f3824;
    }
    .fail {
      color: #a43535;
      text-shadow: -2px 0 #431d15, 0 2px #431d15, 2px 0 #431d15, 0 -2px #431d15;
    }
    line-height: 150%;
    text-align: center;
    @media only screen and (max-width: 576px) {
      font-size: 16px;
  }
  }
  .round-numb-txt {
    font: $fw-normal rem(14px) $font-poppins-regular;
    text-align: center;
    color: #ffffff;
    @media only screen and (max-width: 576px) {
      font-size: 12px;
  }
  }
  .elo-change-txt {
    text-align: center;
    text-transform: uppercase;
    text-shadow: -2px 0 #5f3824, 0 2px #5f3824, 2px 0 #5f3824, 0 -2px #5f3824;
    color: #f0e1c3;
    font: $fw-bold rem(24px) $font-CookieRun-Bold;
  }
  .flex-center {
    display: flex;
    align-items: center;
    &.end {
      justify-content: end;
      margin-right: 14px;
    }
  }
  .address-txt {
    font: $fw-normal rem(14px) $font-poppins-regular;
    line-height: 21px;
    text-align: center;
    margin-left: 14px;
    &.end {
      margin-right: 14px;
      margin-left: 0px;
    }
  }
}

@media only screen and(max-width: 575px) {
  .history-game-page{
     .list-match {
       margin-right: 0;
       .row-result {
         padding: 0;
       }
       .result-container, .player-right .ant-row{
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-end;
          align-items: flex-start;

       }
       .address-txt.end {
         margin: 0;
         padding: 0;
         justify-content: flex-start;
       }
       .address-txt {
         margin-left: 0;
       }
     }
     .elo-change-txt{
       font-size: rem(16px);
     }
     .round-numb-txt {
       margin-left: rem(16px);
     }
  }
}

