@import "../../../../styles/variable";

.deck-tab {
  width: 100%;
  background: #212025;
  border-radius: 9px;
  margin-bottom: rem(30px);
  .action-container{
    font: 700 rem(16px) $font-CookieRun-Bold;
    line-height: rem(21px);
    color: #F0E1C3;
    padding: rem(12px)rem(23px);
    justify-content: space-between;
    &-cancel{
      color: #E94B40;
      padding-right: rem(14px);
      border-right: 1px solid #F0E1C3;
      cursor: pointer;
    }
    &-confirm, &-edit {
      padding-left: rem(14px);
      color:#EBC276;
      cursor: pointer;
    }
    .edit-disable{
      background: linear-gradient(180deg, #82837E 0%, #525150 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      cursor: pointer;
    }
  }  
  .list-deck{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background: #19191D;
    border-radius: 20px 20px 9px 9px;
    padding: 20px 70px;
    .item-empty{
      background-image: url("../../../../assets/images/empty-card-deck.svg");
      width: rem(124px);
      height: rem(180px);
      background-size: cover;
      &:hover{
        box-shadow: 0px 4px 30px rgba(253, 204, 132, 0.2);
        background-image: url("../../../../assets/images/empty-card-deck-hover.svg");
      }
    }
    .no-hover{
      background-image: url("../../../../assets/images/empty-card-deck.svg");
      width: rem(124px);
      height: rem(180px);
      background-size: cover;    
    }
    .item-deck{
      width: rem(124px);
      height: rem(180px);
      background-color: #1C2023;
      border-radius: rem(4px);
      text-align: center;
      position: relative;

      &-container{
        background: #000000;
        opacity: 0.5;
        height: 100%;
        border-radius: rem(4px);
      }

      &__content{
        width: rem(116px);
        height: rem(140px);
      }
      .btn-remove {
        width: rem(58px);
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom:60px;
        border: none !important;
        font-size: 12px;
      }
      
      .id-card {
        width: fit-content;
        color: #CCCCCC;
        font: $fw-normal rem(10px) $font-poppins-regular;
        line-height: rem(12px);
        padding: rem(12px) rem(6px);
        &.card-empty{
          margin-bottom: rem(12px);
        }
      }
    }

  }
  .btn-save{
    margin-top: rem(40px);
  }
  .ant-btn[disabled] {
    background: linear-gradient(180deg, #82837E 0%, #525150 100%);
    color: #E1E2E9;
    .ant-statistic-content-value {
      color: #E1E2E9;
      font-size: 14px;
    }
  }
  
}

.card-deck__content{
    width: rem(110px);
    margin: 0 auto;
    background: #141416;
    border-radius: rem(9px);
    height: rem(201px);
    align-items: center;
    padding: rem(27px) 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #CCCCCC;
    font: $fw-normal rem(10px) $font-poppins-regular;
    position: relative;

    
    .image-card{
      width: rem(85px);
      height: rem(85px);
    }
    .rarity-race{
      position: absolute;
      top: rem(12px);
      right: rem(6px);
      display: flex;
      flex-direction: column;
      img{
        width: rem(16px);
        height: rem(16px);
        margin-bottom: rem(5px);
      }
    }

  }

@media (max-width: 1160px) {
  .deck-tab {
    .list-deck{
      justify-content: center;
      .item-deck, .item-empty{
        margin-right: rem(10px);
        margin-left: rem(10px);
        margin-top: rem(20px);
      }
    }
    .btn-save{
      margin-bottom: rem(30px);
    }
  }

}



