@import "../../../styles/variable";

.game-content {
  .ant-tabs-top > .ant-tabs-nav::before {
        border: none;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    height: 37px;
    align-items: flex-end;
  }

  .ant-tabs-nav {
    padding-top: rem(42px);
    margin-bottom: 0;
  }

  .ant-tabs-tab{
    margin: 0 30px 0 0;
    font: $fw-bold rem(18px) $font-CookieRun-Bold; 
    line-height: rem(30px);

    &-btn {
        color: #E1E2E9;
        opacity: 0.5;
    }
    &-active .ant-tabs-tab-btn {
        color: #EBC276;
        opacity: 1;
    }
  }

  .ant-tabs-ink-bar {
    background: linear-gradient(90deg,rgba(232,159,1,0),#e89f01 47.4%,rgba(232,159,1,0));
  }

  .home-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .list-competitor{
      width: rem(950px);
      padding: rem(30px) 0 rem(100px);

    }      
      .btn-match{
        position: absolute;
        right: rem(8px);
        top: rem(430px);
        background-image: url("../../../assets/images/bg-btn-icon.png");
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        font-family: "CookieRun Bold";
        text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410, 0 -1px #522410;
        width: rem(294px);
        height: rem(112px);
        z-index: 2;
        color: white;
        cursor: pointer;
        .txt-block {
          text-align: center;
          margin-top: 22px;
          margin-left: 25px;
        }
        .big-text{
          font-size: 24px;
          line-height: 100%;
          padding-left: 38px;
        }
        .time-countdown{
          height: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: white;
          font: $fw-bold rem(14px) $font-CookieRun-Bold; 
          // line-height: rem(18px);
          margin-top: 30px;
          margin-left: 60px;
          .ant-statistic-content-value {
            margin-right: 2px;
            color: white;
                      font: $fw-bold rem(20px) $font-CookieRun-Bold; 

          }
        }
      }
      .safe-for{
        z-index: 1;
        position: absolute;
        right: rem(18px);
        top: rem(540px);
        width: rem(257px);
        height: rem(73px);
        background: rgba(240, 230, 217, 0.2);
        border-radius: 7px;
        padding: rem(20px) 0;
        justify-content: center;
        align-items: center;
        color: white;
        margin: -40px auto 0; 
        font: $fw-normal rem(14px) $font-poppins-regular;
        line-height: rem(14px);
        img{
          margin-right: rem(4px);
        }
        &-time {
          margin-left: rem(4px);
          margin-top: rem(-5px);
          .ant-statistic-content-value {
            color: #E89F01;
            font: $fw-normal rem(14px) $font-poppins-regular;
            line-height: rem(14px);
          }
        }
      }
    }
  
}

.confirm-defense{
  color:#E1E2E9;
  font: $fw-normal rem(14px) $font-poppins-regular;
  line-height: rem(21px);
  top: rem(250px);
  .ant-modal-content {
    border-radius: rem(4px);
  }
  .ant-modal-body{
    padding-top: rem(50px);
    border-radius: rem(4px);
  }

  .btn-container{
    display: flex;
    flex-direction: row;
    margin-top: rem(53px);
    margin-bottom: rem(27px);
    .ant-btn {
      margin: 0 10px;
    }
  }
}

@media (max-width: 1222px) {
  .game-content .home-container{
    .list-competitor{
      justify-content: center;
      padding-top: rem(90px);
    }
  }

}

@media (max-width: 575px) {
  .game-content .home-container .list-competitor{
    padding-top: rem(190px);
  }
}

