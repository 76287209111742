@import "../../../styles/variable";
.btn-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer;
  height: 44px;
  width: 179px;
  border-radius: 6px 6px 9px 9px;
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  color: $color-white;
  font-weight: $fw-bold;
  font-size: 14px;
  line-height: 21px;
  border: none;
  margin: 0 1%;
  &:hover {
    color: $color-white;
  }
  &:active {
    color: $color-white;
  }
  &:focus {
    color: $color-white;
  }
  @media only screen and (max-width: 768px) {
    width: 150px;
  }
}

.yellow-btn {
  background: $color-yellow-btn;
  box-shadow: 0px 4px #76510d;

  &:hover {
    background: $color-yellow-btn;
  }
  &:active {
    background: $color-yellow-btn;
  }
  &:focus {
    background: linear-gradient(180deg, #ffae19 0%, #db9310 100%);
  }
}
.brown-btn {
  background: $color-brown-btn;
  box-shadow: 0px 4px #44240e;
  &:hover {
    background: $color-brown-btn;
  }
  &:active {
    background: $color-brown-btn;
  }
  &:focus {
    background: $color-brown-btn;
  }
}
.green-btn {
  background: $color-green-btn;
  box-shadow: 0px 4px #5b610a;
  &:hover {
    background: $color-green-btn;
  }
  &:active {
    background: $color-green-btn;
  }
  &:focus {
    background: $color-green-btn;
  }
}

.brown-btn {
  background: $color-brown-btn;
  box-shadow: 0px 4px #44240e;
  &:hover {
    background: $color-brown-btn;
  }
  &:active {
    background: $color-brown-btn;
  }
  &:focus {
    background: $color-brown-btn;
  }
}

.orange-btn {
  background: $color-orange-btn;
  box-shadow: 0px 4px #ae4b12;
  &:hover {
    background: $color-orange-btn;
  }
  &:active {
    background: $color-orange-btn;
  }
  &:focus {
    background: $color-orange-btn;
  }
}

.gray-btn {
  background: $color-gray-6 !important;
  color: $color-white !important;
  box-shadow: 0px 4px $color-gray-7 !important;
  &:hover {
    background: $color-gray-6;
  }
  &:active {
    background: $color-gray-6;
  }
  &:focus {
    background: $color-gray-6;
  }
}

.red-btn {
  background: $color-red-btn;
  box-shadow: 0px 4px #610a0a;
  border-radius: 6px 6px 9px 9px;
  color: white;
  &:hover,
  &:active,
  &:focus {
    background: $color-red-btn;
    color: white;
  }
}

.atn-btn {
  &-size {
    &-medium {
      min-width: rem(148px);
    }
    &-small {
      min-width: rem(97px);
      height: 44px;
    }
    &-large {
      min-width: rem(344px);
    }
  }
  &-color {
    &-green {
      background: linear-gradient(180deg, #f9c744 0%, #df8c00 100%);
      box-shadow: 0px 4px #ae4b12;
      border-radius: 6px 6px 9px 9px;
      color: white;
      border: none;

      &:hover,
      &:active,
      &:focus {
        background: linear-gradient(180deg, #f9c744 0%, #df8c00 100%);
        color: white;
      }
    }

    &-gardient {
      background: linear-gradient(180deg, #1c2023 0%, #726333 100%);
      border-radius: 6px;
      color: #e1e2e9;
      border: none;
      &:hover,
      &:active,
      &:focus {
        background: linear-gradient(180deg, #1c2023 0%, #726333 100%);
        color: #e1e2e9;
      }
    }
    &-brown {
      background: linear-gradient(180deg, #BE9C80 0%, #68492A 100%);
      box-shadow: 0px 4px 4px #68492ab3;
      color: white;
      border-radius: 6px;

      &:hover,
      &:active,
      &:focus {
        background: linear-gradient(180deg, #BE9C80 0%, #68492A 100%);
        box-shadow: 0px 4px 4px #68492ab3;
        color: white;
      }
    }
  }
}

.btn-card {
  min-height: rem(38px);
  height: fit-content;
  margin: auto;
  padding: 0 rem(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-top: rem(24px);
  span {
    display: flex;
  }

  img {
    width: rem(24px);
    margin-right: rem(8px);
    filter: drop-shadow(0px 4px 9px rgba(120, 129, 48, 0.5));
  }

  .icon-cancel {
    width: rem(12px);
    margin-bottom: rem(2px);

  }

  .purchase {
    font: $fw-normal rem(12px) $font-poppins-light;
    line-height: rem(12px);
    text-align: left;
    margin-top: rem(2px);
    display: flex;
    flex-direction: row;
    align-items: center;


    &__icon {
      line-height: rem(4px);
      width: fit-content;
      margin-right: rem(4px);
      height: fit-content;
    }
  

    .price {
      font: $fw-bold rem(14px) $font-poppins-regular;
      line-height: rem(14px);
      text-align: left;
      margin-top: rem(3px);

    }
  }
}
