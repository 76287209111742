@import "../../../styles/variable";

.status-modal {
  max-width: 28rem;
}

.notify-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  .title {
    font-family: $font-poppins-regular;
    // text-shadow: 0px 2px 0px #f2b109;
    margin-bottom: 40px;
    // -webkit-text-stroke-width: 1px;
    // -webkit-text-stroke-color: $color-black-2;
    color: $color-white;
    font-size: 20px;
    line-height: 21px;
    margin-top: 12px;
    letter-spacing: -0.05em;
    &.pending {
      margin-bottom: 12px;
    }
    &.red-shadow {
      text-shadow: 0px 2px 0px #cc4b4b;
    }
  }
  .else {
    color: $color-white;
    font-size: 14px;
    margin-bottom: 18px;
  }
}
.waiting-modal {
  max-width: 28rem;
  .ant-modal {
    &-content {
      border-radius: rem(8px);
    }

    &-body {
      padding: rem(32px) rem(32px) rem(22px);
    }
  }

  .status-icon {
    width: 56px;
    height: 56px;
  }

  .confirm-block {
    text-align: center;
  }

  .loading-icon {
    width: rem(64px);
    height: rem(64px);
    margin-top: rem(24px);
    animation: loading 2s linear infinite forwards;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .title {
    line-height: rem(28px);
  }

  .guide {
    font-size: rem(14px);
    line-height: rem(24px);
    @include themed() {
      color: t("sub-text");
    }
  }

  .in-progress-button {
    margin-top: rem(17px);
    justify-content: center;
  }
}

.change-net {
  .title-popup {
    font-family: $font-poppins-bold;
    letter-spacing: -0.05em;
  }
  .title-change {
    color: $color-white;
    margin-top: 30px;
    margin-bottom: 54px;
  }
  .ant-modal {
    &-content {
      width: rem(514px);
    }
    &-body {
      padding-top: rem(24px);
    }
  }
}

@media (max-width: 575px) {
  .waiting-modal {
    max-width: rem(332px);
    width: 90% !important;
  }
  .change-net {
    max-width: rem(343px);
    margin: 0 rem(16px) !important;
    width: 100% !important;
    .ant-modal {
      &-content {
        width: 100% !important;
        .wallet-box-line {
          margin-bottom: 4px;
        }
        .title-change {
          margin-bottom: 21px;
        }
      }
    }
  }
}
